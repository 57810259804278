<template>
    <div>
        <div class="confirmation-modal rounded-lg" :data-test-id="dataTestId">
            <div class="confirmation-modal__header d-flex align-center justify-start">
                <slot name="header">
                    <p v-if="title">
                        {{ title }}
                    </p>
                </slot>
            </div>
            <hr class="confirmation-modal__divider" role="presentation" aria-orientation="horizontal" />
            <div class="confirmation-modal__content d-flex flex-grow-0 content-max-width">
                <slot name="content">
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <p v-if="content" v-html="content"></p>
                </slot>
            </div>
            <hr class="confirmation-modal__divider" role="presentation" aria-orientation="horizontal" />
            <div class="confirmation-modal__actions d-flex justify-end align-center">
                <slot name="actions">
                    <BaseButton
                        v-if="cancelButton"
                        class="confirmation-modal__actions-cancel-button"
                        color="primary"
                        text
                        :placeholder="cancelButton"
                        data-test-id="cancel"
                        @click.once="onClickCancelButtonDo" />
                    <BaseButton
                        v-if="confirmButton"
                        class="confirmation-modal__actions-confirm-button"
                        color="primary"
                        :placeholder="confirmButton"
                        data-test-id="confirm"
                        @click.once="onClickConfirmButtonDo" />
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
    import BaseButton from '@core/shared/components/buttons/BaseButton.vue';
    import CloseModalOnRouteChangeMixin from '@core/mixins/modals/CloseModalOnRouteChange';

    export default  ( {
        name: 'ConfirmationModal',
        components: {
            BaseButton
        },
        mixins: [
            CloseModalOnRouteChangeMixin
        ],
        props: {
            title: {
                type: String,
                default: null
            },
            content: {
                type: String,
                default: null
            },
            cancelButton: {
                type: String,
                default: null
            },
            confirmButton: {
                type: String,
                required: true
            },
            onConfirmFn: {
                type: Function,
                required: true
            },
            onCancelFn: {
                type: Function,
                default: null
            },
            dataTestId: {
                type: String,
                default: 'confirmationModal'
            }
        },
        methods: {
            onClickCancelButtonDo( ) {
                this.onCancelFn( );
                this.$emit( 'close' );
            },
            onClickConfirmButtonDo( ) {
                this.onConfirmFn( );
                this.$emit( 'close' );
            }
        }
    } );
</script>


<style lang="scss" scoped>
    @import "@scssVariables";
    @import "@scssMixins";

    .confirmation-modal {
        background-color: theme-color( 'white' ) !important;
        min-height: 148px;

        .confirmation-modal__header {
            height: 50%;
            padding-top: theme-spacing( 6 ) !important;
            overflow-wrap: break-word;

            p {
                font-size: font-size( 'xxxxl' );
                font-weight: font-weight( 'large' );
                margin-left: theme-spacing( 8 );
            }
        }

        .confirmation-modal__divider {
            border: solid;
            border-width: 1px 0 0 !important;
            border-color: #E3E4E5;
        }

        .confirmation-modal__content {
            padding: theme-spacing( 6, 6, 6, 8 ) !important;

            p {
                line-height: line-height( 'xxxxl' );
                margin-bottom: theme-spacing( 0 ) !important;
                font-size: font-size( 'l' );
                font-weight: font-weight( 'regular' );
                max-width: 70%;
            }
        }

        .confirmation-modal__actions {
            height: 50%;
            padding: theme-spacing( 6, 3 ) !important;

            button {
                line-height: line-height( 'xl' ) !important;
                font-size: font-size( 's' ) !important;
                margin-left: theme-spacing( 5 );
            }

            button:first-child {
                margin-left: theme-spacing( 0 );
            }

            .confirmation-modal__actions-cancel-button {
                font-weight: font-weight( 'large' );
            }

            .confirmation-modal__actions-confirm-button {
                font-weight: font-weight( 'bold' );
                text-transform: none;
            }
        }
    }

    @include mobile-only {
        .confirmation-modal {
            .confirmation-modal__header {
                height: 50%;
                padding-top: theme-spacing( 4 ) !important;

                p {
                    line-height: line-height( 'xxxl' ) !important;
                    margin-bottom: theme-spacing( 4 );
                    font-size: font-size( 'l' ) !important;
                    font-weight: font-weight( large );
                    margin-left: theme-spacing( 6 );
                }
            }

            .confirmation-modal__divider {
                border-color: theme-color( 'quinary' );
            }

            .confirmation-modal__content {
                padding: theme-spacing( 4, 5, 6, 6 ) !important;

                p {
                    max-width: 100%;
                }
            }

            .confirmation-modal__actions {
                padding: theme-spacing( 3, 4 ) !important;

                button {
                    padding: theme-spacing( 0, 3 );
                    margin-left: theme-spacing( 2 );
                }
            }
        }
    }
</style>
